
.rc-hidden[data-v-8938f3ba] {
  display: none;
}
.rc-container[data-v-8938f3ba] {
  max-width: 1820px;
}
@media (min-width: 1024px) {
.rc-hidden[data-v-8938f3ba] {
    display: flex;
}
}
.slide-move[data-v-8938f3ba] {
  transition: all 600ms ease-in-out 50ms;
}
.slide-enter-active[data-v-8938f3ba] {
  transition: all 300ms ease-out;
}
.slide-leave-active[data-v-8938f3ba] {
  transition: all 200ms ease-in;
}
.slide-enter[data-v-8938f3ba] {
  transform: translate(-100%, 0);
}
.slide-leave-to[data-v-8938f3ba] {
  transform: translate(-100%, 0);
}
@media (min-width: 1024px) {
.rc-hidden-lg[data-v-8938f3ba] {
    display: none;
}
}
